import React, { useEffect, useState, useRef } from 'react';
import { Line  } from '@ant-design/plots';
import styled from '@emotion/styled';
import COLORS from 'common/Colors';
import { Spin } from 'antd';
import { concat, includes, isNaN, map, max, size, unionBy, find, sumBy } from 'lodash';
import { DatePicker, Space } from 'antd';
import { dateInDetail, getKenyanDateTime } from 'utils/Date';
import { CALL_API } from 'common/API';
import moment from 'moment';
import { connect } from 'react-redux';
import { getClientid } from 'redux/selectors';

const StyledTitle = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  color: #141414;
  margin-bottom: 10px;
  .header{
    display:flex;
    justify-content: space-between;
  }
`;

const StyledChartContainer = styled.div`
  height:380px;
  border: solid 1px #9B9B9B;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 25px;
  .name-title {
    display:flex;
    justify-content:space-between;
    padding: 3px 10px;
    font-size: 1rem;
    padding-bottom: 15px;
  }
`;

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}





function OverTimeAnalysis({ clientid }) {
  const [ displayReportLoader, toggleReportLoader ] = useState(false);

  const data = [
    {
        month:'Jan',
        Hours: "00:00",
        value: 0
    },
    {
        month:'Feb',
        Hours: "00:00",
        value: 0
    },
    {
        month:'Mar',
        Hours: getRandomNumber(45, 60),
        value: 0
    },
    {
        month:'Apr',
        Hours: "00:00",
        value: 0
    },
    {
        month:'May',
        Hours: "00:00",
        value: 0
    },
    {
        month:'Jun',
        Hours: "00:00",
        value: 0
    },
    {
        month:'Jul',
        Hours: "00:00",
        value: 0
    },
    {
        month:'Aug',
        Hours: "00:00",
        value: 0
    },
    {
        month:'Sep',
        Hours: "00:00",
        value: 0
    },
    {
        month:'Oct',
        Hours: "00:00",
        value: 0
    },
    {
        month:'Nov',
        Hours: "00:00",
        value: 0
    },
    {
        month:'Dec',
        Hours: "00:00",
        value: 0
    },
  ]

  function resetCountToZero(data) {
    // Create a new array to avoid modifying the original data
    const newData = data.map(item => (
      {
        ...item,
        Hours: (item.month !== 'Jan' && item.month !== 'Feb') ? 0 : item.Hours
       
    } ));
    return newData;
  }

  function resetAllCountToZero(data) {
    // Create a new array to avoid modifying the original data
    const newData = data.map(item => (
      {
        ...item,
        Hours:  0 
       
    } ));
    return newData;
  }
  const [ reportData, setReportData ] = useState(resetCountToZero(data));
  const config = {
    data:reportData,
    xField: 'month',
    yField: 'value',
    height: 300,
    label:false,
    point: {
      size: 5,
      // shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    // tooltip: {
    //   showMarkers: false,
    // },

    tooltip: {
      title: '',
      formatter: (datum) => {
    
        const monthData = find(reportData, { 'month': datum?.month });
        return {
          name: 'Hours',
          value:monthData?.Hours.replace(/:\d{2}$/, ''),
        };
      },
    },
  
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    yAxis: {
        // max: isNaN(max(map(reportData, 'value'))) ? 100 : max(map(reportData, 'value')) + 15,
    },
    xAxis: {
      label: {
        formatter: (value) => value.slice(0, 3),
        offset: 10,
        autoHide: false,
        autoRotate: true,
      },
      
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
    animationBegin: 0,
    animationDuration: 2000,
    animationEasing: 'ease-in-out'
  }

  const defaultYear = moment().year();

  useEffect(() => { 
    getData(moment(`${defaultYear}`, 'YYYY'));
  }, []);

  const [yearSelected, setYearSelected] = useState(null)
  const [total, setTotal] = useState(227)
  const containerRef = useRef(null);
 
  // Note : This Code Need in Future

  function convertTimeToDecimal(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours + minutes / 100; // Convert minutes to decimal by dividing by 100
}

  const getData = async (year) =>{
    const yearString = year.format('YYYY');
    setYearSelected(yearString)
    toggleReportLoader(true)
    const { ovetimeAnlysisData } = await CALL_API(`mgview-dashboard/${clientid}`, 'post', {
      method: 'overTimeAnlysis',
      parameters: {
        year: yearString,
      },
    });
    const mappedData = ovetimeAnlysisData.map(item => ({
      month: item.Month.substring(0, 3),
      Hours: item.hours,
      value: convertTimeToDecimal(item.hours)
    }));
    setReportData(prevData => {
      const mergedData = unionBy(prevData, mappedData, 'month');
      mergedData.forEach(item => {
        const Hours2 = (find(mappedData, { 'month': item.month }) || {}).value || 0;
        item.value = Hours2;
        const Hours3 = (find(mappedData, { 'month': item.month }) || {}).Hours || "00:00:00";
        item.Hours = Hours3
      });
      return mergedData;
    });
    setTotal(sumBy(reportData, 'value'));
    toggleReportLoader(false)

  }

  // const getData = async(year) =>{
  //   const yearString = year.format('YYYY');
  //   console.log("yearString -->", yearString)
  //   if(yearString === "2023"){
  //     setReportData(data)
  //   }
  //   else if(yearString === "2024"){
  //     setReportData(resetCountToZero(data))
  //   }
  //   else {
  //     setReportData(resetAllCountToZero(data))
  //   }
  //   setYearSelected(yearString)
  // }

  return (
    <>      
      <StyledTitle>
          <div className='header' ref={containerRef} >
              OverTime Analysis
              <DatePicker.YearPicker getPopupContainer={() => containerRef.current}  defaultValue={moment(`${defaultYear}`, 'YYYY')} clearIcon={null} onChange={getData} />
          </div>    
      </StyledTitle>
      <StyledChartContainer>
      <div className='name-title'>
          <p style={{color:'blue'}}>Year : {yearSelected} </p>
          <p>Total hrs of OT for the year <span style={{color:'blue'}}>{total} hrs</span></p>
      </div>
        <Spin spinning={displayReportLoader}>
          <Line 
            {...config}
             />
        </Spin>
      </StyledChartContainer>
    </>
  );
}

const mapStateToProps = state => ({
  clientid: getClientid(state),
});

export default connect(mapStateToProps, null)(OverTimeAnalysis);